import React from "react"
import SEO from "../components/seo"
import { ResponsiveContext } from "../components/context"
import { Header, Footer, SpotLight, Main } from "../components/index"
import { graphql } from "gatsby"
import { GlobalStyle } from "../../src/elements/index"
import styled from "styled-components"
import preview from "../images/preview.jpeg"

const Wrapper = styled.div`
  margin: 30px auto;
  padding: 0 20px;
`

const IndexPage = ({ data, location }) => {
  const page =
    location.pathname[1] === undefined ? 1 : parseInt(location.pathname[1])
  const previewImage = "https://ndailynotes.com" + preview
  return (
    <ResponsiveContext.Provider
      value={{
        page,
        totalPage: parseInt(data.counts.totalCount / 6) + 1,
      }}
    >
      <SEO
        title={"都柏林日常小事"}
        index={page === 1}
        previewImage={previewImage}
        url={"https://ndailynotes.com/" + (page === 1 ? "" : page)}
        type="website"
        description={page !== 1 ? "都柏林日常小事-" + page : "都柏林日常小事"}
      />
      <GlobalStyle />
      <Header siteTitle={data.site.siteMetadata.title} />
      <Wrapper>
        <SpotLight data={data.spotlight} />
        <Main data={data.allMdx} />
      </Wrapper>
      <Footer />
    </ResponsiveContext.Provider>
  )
}

export const query = graphql`
  query PostQuery($offset: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    spotlight: allMdx(
      filter: { frontmatter: { spotlight: { eq: true } } }
      sort: { fields: frontmatter___priority, order: ASC }
    ) {
      nodes {
        id
        frontmatter {
          title
          slug
          featureImage {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
      }
    }
    counts: allMdx(
      filter: {
        frontmatter: { type: { eq: "post" }, spotlight: { eq: false } }
      }
    ) {
      totalCount
    }
    allMdx(
      limit: 6
      skip: $offset
      sort: { fields: frontmatter___date, order: DESC }
      filter: {
        frontmatter: { spotlight: { eq: false }, type: { eq: "post" } }
      }
    ) {
      edges {
        node {
          frontmatter {
            date
            slug
            tag
            title
            excerpt
            featureImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
